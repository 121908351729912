<template>
  <b-container fluid="md" id="login-reset-container">
    <b-card bg-variant="light" class="align-items-center">
      <h1 class="text-center mb-5">{{ $t('login.headline') }}</h1>
      <b-overlay :show="formDisabled" spinner-type="grow" spinner-small rounded="sm" class="flex-fill">
        <b-container class="container flex-column text-center">
          <label for="loginName" class="sr-only" aria-hidden="true">{{ $t('login.username') }}</label>
          <b-input
            id="loginName"
            type="text"
            name="username"
            v-model="input.username"
            v-bind:placeholder="$t('login.username')"
            v-on:keyup.enter="login"
            autocomplete="username"
            required
            autofocus
          />
          <label for="loginPass" class="sr-only" aria-hidden="true">{{ $t('login.password') }}</label>
          <b-input
            id="loginPass"
            type="password"
            name="password"
            v-model="input.password"
            v-bind:placeholder="$t('login.password')"
            v-on:keyup.enter="login"
            autocomplete="current-password"
            required
            minlength="8"
            maxlength="200"
          />

          <b-button variant="primary" v-on:click="login" class="w-100 my-3">{{ $t('login.button') }}</b-button>

          <router-link
            class="mt-1 btn btn-outline-secondary btn-sm m-0 p-0 px-1"
            :to="{ name: 'account_passwordResetRequest' }"
            custom
            v-slot="{ navigate }"
          >
            <span @click="navigate" @keypress.enter="navigate" role="link">{{ $t('login.reset') }}</span>
          </router-link>
        </b-container>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'

export default {
  name: 'MainLogin',
  mixins: [ApiMixin, NotificationMixin],
  data() {
    return {
      input: {
        username: '',
        password: ''
      },
      formDisabled: false
    }
  },
  methods: {
    async login() {
      if (this.input.username !== '' && this.input.password !== '') {
        this.formDisabled = true
        let bodyFormData = {}
        bodyFormData.username = this.input.username
        bodyFormData.password = this.input.password
        let self = this
        this.postRequest(
          '/login',
          bodyFormData,
          new RequestConfig()
            .onSuccess(res => {
              if (res) {
                self.$store.commit('setToken', res.headers.authorization)
                self.loadUser().then(() => {
                  let redirectUrl = self.$route.query.redirectUrl
                  if (redirectUrl && !redirectUrl.match('^/log(out|in)')) {
                    self.$router.push(redirectUrl)
                  } else if (this.isDataScientist) {
                    self.$router.push({ name: 'datasets' })
                  } else {
                    self.$router.push({ name: 'upload' })
                  }
                })
              }
            })
            .onError(err => {
              self.formDisabled = false
              if (err.response !== undefined && err.response.data !== undefined) {
                const message = err.response.data.message
                if (message === 'authentication.user-disabled') {
                  self.displayError(self.$t('login.inactive-user-login'))
                } else if (message === 'authentication.bad-credentials') {
                  let errorMessage = self.$t('login.bad-credentials')
                  let remainingAttempts = err.response.data.remainingLoginAttempts
                  if (remainingAttempts && remainingAttempts > 1) {
                    errorMessage += '. ' + self.$t('login.remaining-attempts', { remainingAttempts: remainingAttempts })
                  } else if (remainingAttempts && remainingAttempts === 1) {
                    errorMessage += '. ' + self.$t('login.remaining-attempt')
                  }
                  self.displayError(errorMessage)
                } else if (message === 'authentication.bad-credentials-now-locked') {
                  self.displayError(self.$t('login.bad-credentials-now-locked'))
                } else if (message === 'authentication.user-locked') {
                  self.displayError(self.$t('login.locked-user'))
                } else if (err.response.data.error === 'Not Found') {
                  self.displayError(self.$t('login.failed-not-found-msg'))
                } else {
                  self.displayError(self.$t('login.failed-default-msg'))
                }
              }
            })
        ).catch(() => {
          // re-enable login form if an API error occurs
          self.formDisabled = false
        })
      } else {
        this.displayError(this.$t('login.data-missing'))
      }
    },
    loadUser() {
      let self = this
      return this.getRequest(
        '/account/current',
        new RequestConfig()
          .onSuccess(res => {
            if (res) {
              self.$store.commit('setCurrentUser', res.data)
            }
          })
          .onError(err => {
            console.log(err)
            self.$router
              .push({ name: 'logout' })
              .then(() => self.displayError(self.$t('login.load-userdata-error')))
              .catch(() => {})
          })
      )
    }
  }
}
</script>

<style lang="scss" scoped>
#login-reset-container #loginName {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#login-reset-container #loginPass {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#login-reset-container .form-control {
  position: relative;
  height: auto;
  padding: 10px;
}

#login-reset-container .form-control:focus {
  z-index: 2;
}
</style>
